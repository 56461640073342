<template>
    <div style="height: 65px; line-height: 50px; border-bottom: 1px solid #cccccc; display: flex">
<!--        <img :src="require('@/assets/logo.png')" style="width: 45px;height: 60px;"/>-->
<!--        <h2 style="margin: auto">和信资产</h2>-->


        <div style="margin:auto;width: 200px; padding-left: 30px;font-size: 25px; font-weight: bold; color: #0d84ff">
            话务中心</div>
        <div style="flex: 1"></div>
        <div  style="width: 120px;margin:auto 20px;text-align:center;" class="avatar-area">
            <el-dropdown>
                <span class="el-dropdown-link" style="cursor: pointer;margin: auto;font-size: medium;">
                  {{User.user_name}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                    <el-dropdown-menu  slot="dropdown">
<!--                    <el-dropdown-item @click="$router.push('/person')">个人信息</el-dropdown-item>-->
                        <el-dropdown-item @click="changeInfoDialog" icon="el-icon-user">个人信息</el-dropdown-item>
                        <el-dropdown-item @click="changeDialog" icon="el-icon-key" divided>修改密码</el-dropdown-item>
                        <el-dropdown-item @click="logout()" icon="el-icon-switch-button" divided>退出系统</el-dropdown-item>
                        <!--<el-dropdown-item @click="websocketonopen">发送数据</el-dropdown-item>-->
                    </el-dropdown-menu>

                </template>
            </el-dropdown>
        </div>
        <div style="cursor:pointer;display:flex;line-height:25px;flex-direction: column;
            justify-content: center;align-items: flex-end;border: 1px;border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);border-style:none dotted;">
            <span style="font-size: small;">
                来电弹屏
                <el-tooltip class="item" style="cursor: pointer;font-size: medium;"
                            effect="dark" content="来电弹屏已开启" placement="left" v-if="wsConnected">
                  <i class="el-icon-success" style="color: #67c23a"></i>
                </el-tooltip>
                <el-tooltip class="item" style="cursor: pointer;font-size: medium;"
                            effect="dark" content="来电弹屏未开启" placement="left" v-else>
                  <i class="el-icon-error" style="color: #f56c6c" ></i>
                </el-tooltip>
            </span>
            <span style="font-size: small;">
                软电话在线
                <el-tooltip class="item" style="cursor: pointer;font-size: medium;"
                            effect="dark" content="软电话当前在线" placement="left" v-if="softPhoneOnline">
                  <i class="el-icon-success" style="color: #67c23a"></i>
                </el-tooltip>
                <el-tooltip class="item" style="cursor: pointer;font-size: medium;"
                            effect="dark" content="软电话当前离线,无法拨打电话" placement="left" v-else>
                  <i class="el-icon-error" style="color: #f56c6c" ></i>
                </el-tooltip>

            </span>
        </div>
        <!--修改密码弹窗-->
        <el-dialog title="修改密码" v-model="changeVisible" width="30%"  :before-close="handleClose">
            <el-form ref="form1" :model="changePassForm" label-width="120px" :rules="rules">
                <el-form-item label="当前账户密码" prop="oldPass">
                    <el-input  v-model="changePassForm.oldPass" type="password" autocomplete="off" style="width: 75%"></el-input>
                </el-form-item>

                <el-form-item label="新密码" prop="newPass">
                    <el-input  v-model="changePassForm.newPass" type="password" style="width: 75%"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="newPassTwo">
                    <el-input  v-model="changePassForm.newPassTwo" type="password" style="width: 75%"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="changeVisible = false">取 消</el-button>
                    <el-button type="primary" @click="changePass" :loading="changePassBtnLoading">修 改</el-button>
                  </span>
            </template>
        </el-dialog>


        <!--来电弹窗-->
        <el-dialog title="来电信息(外部来电)" v-model="recallVisible" width="30%"  :before-close="recallhandleClose">
            <!--<span>{{recalldata}}</span>-->
            <el-form ref="form2" :model="incallForm" label-width="120px">
                <el-form-item label="客户名称">
                    <el-input v-model="incallForm.client_name" readonly style="width: 75%"></el-input>
                </el-form-item>
                <el-form-item label="客户手机号">
                    <el-input v-model="incallForm.client_num" readonly  style="width: 75%"></el-input>
                </el-form-item>
                <el-form-item label="归属地">
                    <el-input v-model="incallForm.client_num_area" readonly  style="width: 75%"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-tag type="warning" effect="dark"
                            v-if="incallForm.client_state==1">未分配</el-tag>
                    <el-tag type="success" effect="dark"
                            v-else-if="incallForm.client_state==2">已分配</el-tag>
                    <el-tag type="primary" effect="dark"
                            v-else-if="incallForm.client_state==3">已完成</el-tag>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input type="textarea" rows="6" v-model="incallForm.client_remark" readonly style="width: 75%"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="recallhandleClose">关闭</el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog title="来电信息(内部来电)" v-model="recallVisible2" width="30%"  :before-close="recallhandleClose2">
<!--            <span>{{recalldata}}</span>-->
            <el-form ref="form2" :model="incallForm2" label-width="120px">
                <el-form-item label="员工名称">
                    <el-input v-model="incallForm2.user_name" readonly style="width: 75%"></el-input>
                </el-form-item>
                <el-form-item label="员工分机号">
                    <el-input v-model="incallForm2.user_ext_num" readonly  style="width: 75%"></el-input>
                </el-form-item>
                <el-form-item label="员工所属团队">
                    <el-input v-model="incallForm2.team.team_name" readonly  style="width: 75%"></el-input>
                </el-form-item>
                <el-form-item label="权限等级">
                    <el-tag type="danger" effect="dark"
                            v-if="incallForm2.roles[0].id==1" style="width: 80px;text-align:center;">{{incallForm2.roles[0].role_name}}</el-tag>
                    <el-tag type="warning" effect="dark"
                            v-else-if="incallForm2.roles[0].id==2" style="width: 80px;text-align:center;">{{incallForm2.roles[0].role_name}}</el-tag>
                    <el-tag effect="dark"
                            v-else-if="incallForm2.roles[0].id==3" style="width: 80px;text-align:center;">{{incallForm2.roles[0].role_name}}</el-tag>
                    <el-tag type="info" effect="dark"
                            v-else-if="incallForm2.roles[0].id==4"  style="width: 80px;text-align:center;">{{incallForm2.roles[0].role_name}}</el-tag>
                </el-form-item>


            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="recallhandleClose2">关闭</el-button>
              </span>
            </template>
        </el-dialog>




        <el-dialog title="个人信息" v-model="changeInfoVisible" width="30%">

            <el-form ref="form2" :model="editInfoForm" label-width="120px">
                <el-form-item label="账号">
                    <el-input v-model="editInfoForm.user_num"  style="width: 75%"></el-input>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="editInfoForm.user_name" style="width: 75%"></el-input>
                </el-form-item>

                <el-form-item label="所属团队">
                    <span style="font-size: small" >{{editInfoForm.team.team_name}}</span>

                </el-form-item>

                <el-form-item label="分机号码">

                    <span style="font-size: small" >{{editInfoForm.user_ext_num}}</span>

                </el-form-item>

                <el-form-item label="分机密码">
                    <span style="font-size: small" >{{editInfoForm.user_ext_password}}</span>

                </el-form-item>




                <el-form-item label="权限等级">
                    <el-tag type="danger" effect="dark"
                            v-if="editInfoForm.roles[0].code == 'ROLE_ADMIN'" style="width: 80px;text-align:center;">
                        {{editInfoForm.roles[0].role_name}}</el-tag>
                    <el-tag type="warning" effect="dark"
                            v-else-if="editInfoForm.roles[0].code == 'ROLE_REGION'" style="width: 80px;text-align:center;">{{editInfoForm.roles[0].role_name}}</el-tag>
                    <el-tag effect="dark"
                            v-else-if="editInfoForm.roles[0].code == 'ROLE_TEAM'" style="width: 80px;text-align:center;">{{editInfoForm.roles[0].role_name}}</el-tag>
                    <el-tag type="info" effect="dark"
                            v-else-if="editInfoForm.roles[0].code == 'ROLE_STAFF'"  style="width: 80px;text-align:center;">{{editInfoForm.roles[0].role_name}}</el-tag>
                </el-form-item>
            </el-form>
            <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="changeInfoVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editSub" :loading="infoChangeBtnLoading">修 改</el-button>
                  </span>
            </template>
        </el-dialog>
    </div>
</template>


<script>
    import request from "../../utils/request";

    export default {
        name: "Header",
        data(){
            return{
                User:{},
                editInfoForm:{},
                changePassForm:{},
                incallForm:{},
                incallForm2:{},
                changeVisible:false,
                changeInfoVisible:false,
                oldPass: '',
                newPass: '',
                newPassTwo:'',
                recallVisible:false,
                recallVisible2:false,
                wsConnected:false,
                softPhoneOnline:false,
                timer:'',

                infoChangeBtnLoading:false,
                changePassBtnLoading:false,

                rules: {
                    oldPass: [
                        {required: true, message: '请输入旧密码', trigger: 'blur'},
                    ],
                    newPass: [
                        {required: true, message: '请输入新密码', trigger: 'blur'},
                    ],
                    newPassTwo: [
                        {required: true, message: '请再次输入新密码', trigger: 'blur'},
                    ],
                },
                recalldata:'',
            }
        },

        created() {
            this.load()
            this.softPhoneOnline=this.User.user_ext_online
            //websocket初始化
            this.initWebSocket()


        },
        mounted(){
            this.timer = setInterval(this.timeout, window.server.verifyInterval)
        },
		methods:{
            load(){
                this.refreshUser()
            },
            refreshUser(){
                let str = sessionStorage.getItem("user") || "{}";
                this.User=JSON.parse(str);
            },
            timeout(){
                var now=Math.round(new Date() / 1000)
                var expireTime=parseInt(sessionStorage.getItem("expire"))+window.server.differExpire*60

                if(now>expireTime){
                    this.$message.error("您已长时间不活跃，强制下线")
                    this.logout();

                    //console.log("超时");
                }else {
                    //console.log("未超时 "+now+" "+expireTime);
                }
            },
			logout(){
				sessionStorage.removeItem("token");
				sessionStorage.removeItem("user");
                if(this.wsConnected){
                    this.websock.close() //离开路由之后断开websocket连接
                }
                clearInterval(this.timer)
				this.$router.push('/login');
			},
            changePass(){
                this.$refs['form1'].validate((valid) => {
                    if (valid) {
                        if (!this.changePassForm.oldPass) {
                            this.$message.error("请填写旧密码")
                            return
                        }
                        if (!this.changePassForm.newPass) {
                            this.$message.error("请填写新密码")
                            return
                        }
                        if (!this.changePassForm.newPassTwo) {
                            this.$message.error("请确认新密码")
                            return
                        }
                        if(this.changePassForm.newPass!==this.changePassForm.newPassTwo){
                            this.$message.error("两次输入的密码不一致")
                            return
                        }
                        if(this.changePassForm.oldPass==this.changePassForm.newPass){
                            this.$message.error("旧密码和新密码相同")
                            return
                        }
                        var changePassParam={
                            'user_id':this.User.user_id,
                            'user_password':this.changePassForm.oldPass,
                            'new_user_password':this.changePassForm.newPass
                        }

                        // this.editInfoForm.user_password=this.changePassForm.newPass
                        this.changePassBtnLoading=true
                        request.post("/changePass",changePassParam).then(res =>{
                            if(res.code === 200){
                                this.$message({
                                    type:"success",
                                    message:"修改密码成功,请重新登录"
                                })
                                this.changeVisible = false  // 关闭弹窗
                                this.logout()

                            }
                            else{
                                this.$message({
                                    type:"error",
                                    message:res.msg,
                                })
                            }
                            this.changePassBtnLoading=false
                        }).catch(e=>{
                            this.changePassBtnLoading=false
                            this.$message({
                                type:"error",
                                message:'请求失败,服务器错误',
                            })
                        })



                    }
                })
            },
            changeDialog(){
                this.editInfoForm = JSON.parse(JSON.stringify(this.User))//深拷贝
                this.changeVisible=true;
            },
            handleClose(){
                this.$refs['form1'].resetFields();
                this.changeVisible=false;
            },
            changeInfoDialog(){
                this.editInfoForm = JSON.parse(JSON.stringify(this.User))//深拷贝
                this.changeInfoVisible=true;

            },
            editSub(){
                this.infoChangeBtnLoading=true
                request.post("/changeInfo",this.editInfoForm).then(res =>{
                    if(res.code === 200){
                        this.$message({
                            type:"success",
                            message:"修改成功"
                        })
                        this.changeInfoVisible = false  // 关闭弹窗
                        sessionStorage.setItem("user",JSON.stringify(this.editInfoForm))
                        this.load()
                    }
                    else{
                        this.$message({
                            type:"error",
                            message:res.msg,
                        })
                    }
                    this.infoChangeBtnLoading=false
                }).catch(e=>{
                    this.infoChangeBtnLoading=false
                    this.$message({
                        type:"error",
                        message:'请求失败,服务器错误',
                    })
                })

            },
            //来电信息关闭之前
            recallhandleClose(){
                this.recalldata=''
                this.recallVisible=false;
            },
            recallhandleClose2(){
                this.recalldata=''
                this.recallVisible2=false;
            },


            //websocket连接
            initWebSocket(){ //初始化weosocket
                const wsuri = window.server.wsUrl
                this.websock = new WebSocket(wsuri);
                this.websock.onmessage = this.websocketonmessage;
                this.websock.onopen = this.websocketonopen;
                this.websock.onerror = this.websocketonerror;
                this.websock.onclose = this.websocketclose;
            },
            websocketonopen(){ //连接建立之后执行send方法发送数据
                //let actions = '来电弹屏已开启';
                //this.websocketsend(JSON.stringify(actions));
                //this.websock.send(actions);
                var wsParam={}
                wsParam.type="token"
                wsParam.data=sessionStorage.getItem("token");
                this.websocketsend(JSON.stringify(wsParam))

            },
            websocketonerror(){//连接建立失败重连
                this.wsConnected=false;
                setTimeout(this.initWebSocket(),1000)
            },
            websocketonmessage(e){ //数据接收
                var res=JSON.parse(e.data)
                //console.log('接收到的数据',res.data)
                if(res.type=="status"){
                    if(res.data=="connected"){
                        this.wsConnected=true
                        //console.log("连接成功")
                    }
                    if(res.data=="error"){
                        this.wsConnected=false
                        this.initWebSocket()
                        //console.log("连接成功")
                    }
                    if(res.data=="sso-offline"){//其他地方登录，单点登录
                        this.$message.error("您的账号已在其它地点登录")
                        this.logout()
                    }
                }
                if(res.type=="softphone"){
                    if(res.data=="offline"){
                        this.softPhoneOnline=false
                    }
                    if(res.data=="online"){
                        this.softPhoneOnline=true
                    }
                }
                if(res.type=="outsideCall"){
                    this.incallForm=JSON.parse(res.data);
                    this.recallVisible=true;
                }
                if(res.type=="insideCall"){
                    this.incallForm2=JSON.parse(res.data);
                    this.recallVisible2=true;
                }

            },
            websocketsend(Data){//数据发送
                this.websock.send(Data);
            },
            websocketclose(e){  //关闭
                this.wsConnected=false;
                //console.log('断开连接',e);
            },

        }


    }
</script>
