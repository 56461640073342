import {createRouter, createWebHistory} from 'vue-router'
//import {createRouter, createWebHashHistory} from 'vue-router'//hash模式

import Layout from '../layout/Layout.vue'
const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: "/login",
    children: [
      {
        path: '/user',
        name: 'user',
        component:() => import("@/views/User"),
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component:() => import("@/views/Dashboard"),
      },
      {
        path: '/callrecord',
        name: 'callrecord',
        component:() => import("@/views/CallRecord"),
      },
      {
        path: '/tasklist',
        name: 'tasklist',
        component:() => import("@/views/Tasklist"),
      },
      {
        path: '/client',
        name: 'client',
        component:() => import("@/views/Client"),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //hash模式
  //mode:'hash',
  //history: createWebHashHistory(),


  routes
})




export default router
