<template>
    <div>
        <el-menu
                style="width: 200px; min-height: calc(100vh - 50px)"
                :default-active="active"
                router
                class="el-menu-vertical-demo">
<!--            <el-menu-item index="dashboard">-->
<!--                <i class="el-icon-menu"></i>-->
<!--                <template #title>控制面板</template>-->
<!--            </el-menu-item>-->
            <el-menu-item index="tasklist">
                <i class="el-icon-s-claim"></i>
                <template #title>任务列表</template>
            </el-menu-item>
            <el-menu-item index="callrecord">
                <i class="el-icon-document"></i>
                <template #title>通话记录</template>
            </el-menu-item>
            <el-menu-item index="user" v-if="isAdmin">
                <i class="el-icon-service"></i>
                <template #title>员工信息</template>
            </el-menu-item>
            <el-menu-item index="client" v-if="isAdmin">
                <i class="el-icon-s-custom"></i>
                <template #title>客户信息</template>
            </el-menu-item>

        </el-menu>
    </div>
</template>

<script>
    export default {
        name: "Aside",
        data(){
            return{
                user:{},
                isAdmin:false,
                active:this.$route.name,
            }
        },
        created() {
            let str = sessionStorage.getItem("user") || "{}"
            this.user = JSON.parse(str)
            if(this.user.roles[0].code != "ROLE_STAFF"){
                this.isAdmin=true;
            }
        }
    }
</script>

<style scoped>

</style>
