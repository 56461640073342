import axios from 'axios'
import router from "@/router";
import jwtDecode from 'jwt-decode'


const request = axios.create({
    baseURL:'/dial',
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    // config.headers['token'] = user.token;  // 设置请求头

    config.headers.Authorization=sessionStorage.getItem("token")

    let userJSON = sessionStorage.getItem("user")
    if(!userJSON){
        router.push("/login")
    }

    return config
}, error => {
    return Promise.reject(error)
});
function logout() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    //this.$store.state.isLogined=false;
    router.push("/login");
    //console.log("登出");
}

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {

        let res = response.data

        // console.log(response.headers)
        // console.log(response.config)
        // console.log(response.data)
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return response
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if(res.token!=null){

            window.sessionStorage.setItem("token","Bearer "+res.token)
            var authToken=jwtDecode(res.token)

            sessionStorage.setItem("user", authToken.user)  // 缓存用户信息

            //console.log("刷新token,过期时间:"+authToken.exp)
            sessionStorage.setItem("expire", authToken.exp)
        }
		if(res.code===401){
			router.push("/login")
		}
		if(res.code===402){//token刷新了
            return request(response.config)
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)



export default request

